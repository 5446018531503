import { CloseIcon } from '@c/icons';
import { Combobox } from '@headlessui/react';
import { saveSearchQuery } from '@util/firestore/search';
import { useAuth } from 'context/AuthContext';
import { useSearch } from 'context/SearchContext';
import { useRouter } from 'next/navigation';
import Button from './Button';
import Input from './Input';
import { injectWebSiteScript } from 'features/schemaMarkups';
import useNavigationEvent from '@util/hooks/useNavigationEvent';
import { useCallback } from 'react';

interface SearchBoxProps {
  onBlur?: () => void;
  onFocus?: () => void;
  onMobileFocus?: () => void;
  goBack?: () => void;
  onClick?: () => void;
  onEnter?: () => void;
  onClearClick?: () => void;
  searchIconRight?: boolean;
  dismissOnBlur?: boolean;
  showCloseButton?: boolean;
  autoFocus?: boolean;
}
const SearchBox = ({
  onBlur,
  onFocus,
  goBack,
  onClick,
  onEnter,
  showCloseButton,
  onClearClick,
  autoFocus,
  onMobileFocus,
}: SearchBoxProps) => {
  const {
    searchTerm,
    setSearchTerm,
    setShowSearchPreview,
    setShowMobileSearchbar,
  } = useSearch();
  const { userDoc } = useAuth();

  const router = useRouter();
  const navigate = (keyword: string) => {
    setSearchTerm('');
    return router.push(`/search?keyword=${keyword}`);
  };

  useNavigationEvent(
    useCallback(({ url, previousUrl, searchParams, previousSearchParams }) => {
      const handleRouteChange = () => {
        setSearchTerm('');
        setShowSearchPreview(false);
        setShowMobileSearchbar(false);
      };
      if (
        url !== previousUrl ||
        searchParams?.get('keyword') !== previousSearchParams?.get('word')
      ) {
        handleRouteChange();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  );

  return (
    <div className="relative z-30 mx-auto w-full max-w-[70rem]">
      <script
        id="websiteSchema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html:
            injectWebSiteScript({
              url: 'https://mxlocker.com/',
              searchUrlTemplate:
                'https://mxlocker.com/search?keyword={search_term_string}',
            }) ?? '',
        }}
      />
      <Combobox
        as="div"
        value={searchTerm}
        onChange={(value: string) => setSearchTerm(value)}
      >
        <Input
          goBack={goBack}
          bordered
          full
          autoFocus={autoFocus}
          id="search-input"
          type="search"
          value={searchTerm}
          placeholder="Search for anything..."
          className="!rounded-2xl border-none bg-[##F1F1F1] p-[2.5rem] sm:text-[2rem] lg:mt-0"
          iconClassName={'text-zinc-500 h-[2.4rem] w-[2.4rem]'}
          trailingIconButton={
            (searchTerm || showCloseButton) && (
              <Button
                type="text"
                width="small"
                noXPadding
                onClick={() => {
                  setSearchTerm('');
                  setShowSearchPreview(false);
                  onClearClick?.();
                }}
                className="!text-zinc-500"
                leadingIcon={<CloseIcon />}
              />
            )
          }
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
          onChange={(ev) => {
            setSearchTerm(ev.target.value);
            setShowSearchPreview(!!ev.target.value);
          }}
          onKeyDown={(ev) => {
            if (ev.key == 'Enter') {
              const keyword = (ev.target as HTMLInputElement).value;
              if (keyword) {
                // NOTE: is this the best place to put this? would be nice to have some memoization.
                // Before searching, lets save the search term to the DB so we can log that they searched for something
                if (!!userDoc) {
                  saveSearchQuery({
                    query: searchTerm ?? '',
                    uid: userDoc.uid,
                  });
                }
                navigate(encodeURIComponent(keyword));
                onEnter?.();
              }
            }
          }}
          onFocus={() => {
            onFocus?.();

            if (onMobileFocus) {
              onMobileFocus();
              setShowSearchPreview(true);
            }
          }}
          onBlur={() => {
            onBlur?.();

            if (onMobileFocus) {
              if (searchTerm === '') {
                setShowSearchPreview(false);
              }
            }
          }}
        />
      </Combobox>
    </div>
  );
};

export default SearchBox;
